import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/feature-request"

const useFeatureRequestEndpoints = () => {
    const create = generatePostEndpoint<{featureRequest: any}, any>(baseURL + "/create");

    return {
        create,
    }
}

export {
    baseURL,
    useFeatureRequestEndpoints
};